import ContactUs from "@/components/Contact";
import { graphql, StaticQuery } from "gatsby";
import React from "react";

export default function Contact(): JSX.Element {
  return (
    <StaticQuery
      query={graphql`
        query GET_CONTENT_FOR_CONTACT_US {
          contentfulContactUsPage {
            seo {
              metaTitle
              metaDescription
              metaUrl
              metaAuthor
              metaKeywords
              metaImage {
                file {
                  url
                }
              }
            }

            # Footer Content
            footerContent {
              raw
            }
          }
        }
      `}
      render={({ contentfulContactUsPage: { seo, footerContent } }) => (
        <ContactUs props={{ seo, footerContent }} />
      )}
    />
  );
}
