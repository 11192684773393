import { SEOType } from "@/types";
import loadable from "@loadable/component";
import React from "react";
import Layout from "../App/Layout";
import SEO from "../SEO";
import BreadCrumb from "../Shared/extra/BreadCrumb";
import ContactForm from "./ContactForm";

const ContactInfo = loadable(() => import("./ContactInfo"));

export default function ContactUs(props: {
  props: { seo: SEOType; footerContent: any };
}): JSX.Element {
  return (
    <Layout footerContent={props.props.footerContent}>
      <SEO contentfulSeo={props.props.seo} />
      <BreadCrumb
        pageTitle="Contact Us"
        paths={[
          { path: "/", pathName: "Home" },
          { path: "/contact/", pathName: "Contact Us" },
        ]}
      />
      <ContactInfo />
      <ContactForm />
    </Layout>
  );
}
