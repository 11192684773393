import React, { FC } from "react";

const ContactForm: FC = () => {
  return (
    <section className="contact-area pb-100">
      <div className="container">
        <div className="section-title">
          <span className="sub-title">
            <img src={"/images/star-icon.png"} alt="contact" />
            Get in Touch
          </span>
          <h2>Ready to Get Started?</h2>
        </div>

        <div className="row">
          <div className="col-lg-4 col-md-12">
            <div className="contact-image">
              <img src={"/images/contact.png"} alt="contact" />
            </div>
          </div>

          <div className="col-lg-8 col-md-12">
            <div className="contact-form">
              <div
                className="typeform-widget"
                data-url="https://form.typeform.com/to/R2YP9bUA?typeform-medium=embed-snippet"
                data-transparency="100"
                data-hide-headers="true"
                data-hide-footer="true"
              >
                <div className="widget__WidgetWrapper-sc-1rxjz1n-0 NwqzB">
                  <div className="widget__IframeWrapper-sc-1rxjz1n-2 cKLpKc">
                    <iframe
                      frameBorder="0"
                      height="100%"
                      src="https://form.typeform.com/to/R2YP9bUA?typeform-medium=embed-snippet?typeform-embed=embed-widget&amp;embed-hide-footer=true&amp;embed-hide-headers=true&amp;embed-opacity=50&amp;typeform-embed-id=136dm"
                      width="100%"
                      allow="camera; microphone; autoplay; encrypted-media;"
                      data-qa="iframe"
                      title="typeform-embed"
                      style={{ border: "0px", minHeight: "300px" }}
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactForm;
